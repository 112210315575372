import IconComment from '@icons/comment.svg';
import dynamic from 'next/dynamic';
import { memo, MemoExoticComponent, MouseEventHandler, ReactElement } from 'react';

const IconAdmin = dynamic(() => import('@icons/admin.svg'));
const IconArrowClockwise = dynamic(() => import('@icons/arrow-clockwise.svg'));
const IconArrowCounterClockwise = dynamic(() => import('@icons/arrow-counterclockwise.svg'));
const IconArrowEnter = dynamic(() => import('@icons/arrow-enter.svg'));
const IconArrowSubmit = dynamic(() => import('@icons/arrow-submit.svg'));
const IconArrowsDown = dynamic(() => import('@icons/arrows-down.svg'));
const IconArrowsRight = dynamic(() => import('@icons/arrows-right.svg'));
const IconAssist = dynamic(() => import('@icons/assist.svg'));
const IconBanner = dynamic(() => import('@icons/banner.svg'));
const IconBasePlace = dynamic(() => import('@icons/base-place.svg'));
const IconBell = dynamic(() => import('@icons/bell.svg'));
const IconBlocks = dynamic(() => import('@icons/blocks.svg'));
const IconCalendar = dynamic(() => import('@icons/calendar.svg'));
const IconCalendarWithDays = dynamic(() => import('@icons/calendar-with-days.svg'));
const IconCancelledGoal = dynamic(() => import('@icons/cancelled-goal.svg'));
const IconCancelledOwnGoal = dynamic(() => import('@icons/cancelled-own-goal.svg'));
const IconCarrousel = dynamic(() => import('@icons/carrousel.svg'));
const IconCheck = dynamic(() => import('@icons/check.svg'));
const IconCheckCircle = dynamic(() => import('@icons/check-circle.svg'));
const IconChevronDown = dynamic(() => import('@icons/chevron-down.svg'));
const IconCoach = dynamic(() => import('@icons/coach.svg'));
const IconCode = dynamic(() => import('@icons/code.svg'));
const IconCommentEmpty = dynamic(() => import('@icons/comment-empty.svg'));
const IconCommentHide = dynamic(() => import('@icons/comment-hide.svg'));
const IconCommentLock = dynamic(() => import('@icons/comment-lock.svg'));
const IconCommentShow = dynamic(() => import('@icons/comment-show.svg'));
const IconCommentUnlock = dynamic(() => import('@icons/comment-unlock.svg'));
const IconCommentAdd = dynamic(() => import('@icons/add-comment.svg'));
const IconConnect = dynamic(() => import('@icons/connect.svg'));
const IconDeleteTrash = dynamic(() => import('@icons/delete-trash.svg'));
const IconDossier = dynamic(() => import('@icons/dossier.svg'));
const IconDots = dynamic(() => import('@icons/dots.svg'));
const IconDoubleDownCenter = dynamic(() => import('@icons/double-down-center.svg'));
const IconDoubleUpCenter = dynamic(() => import('@icons/double-up-center.svg'));
const IconDown = dynamic(() => import('@icons/down.svg'));
const IconDownCenter = dynamic(() => import('@icons/down-center.svg'));
const IconDraw = dynamic(() => import('@icons/draw.svg'));
const IconEdit = dynamic(() => import('@icons/edit.svg'));
const IconEditor = dynamic(() => import('@icons/editor.svg'));
const IconField = dynamic(() => import('@icons/field.svg'));
const IconFilter = dynamic(() => import('@icons/filter.svg'));
const IconFlag = dynamic(() => import('@icons/flag.svg'));
const IconFootball = dynamic(() => import('@icons/football.svg'));
const IconFootballRed = dynamic(() => import('@icons/own-goal.svg'));
const IconGlobe = dynamic(() => import('@icons/globe.svg'));
const IconGraph = dynamic(() => import('@icons/graph.svg'));
const IconGreenOrangeBall = dynamic(() => import('@icons/green-orange-ball.svg'));
const IconHide = dynamic(() => import('@icons/hide.svg'));
const IconHideUser = dynamic(() => import('@icons/hide-user.svg'));
const IconHome = dynamic(() => import('@icons/home.svg'));
const IconLeague = dynamic(() => import('@icons/league.svg'));
const IconLeft = dynamic(() => import('@icons/left.svg'));
const IconLeftCenter = dynamic(() => import('@icons/left-center.svg'));
const IconLike = dynamic(() => import('@icons/like.svg'));
const IconLikeFilled = dynamic(() => import('@icons/like-filled.svg'));
const IconListBig = dynamic(() => import('@icons/list-big.svg'));
const IconListOl = dynamic(() => import('@icons/list-ol.svg'));
const IconListSmall = dynamic(() => import('@icons/list-small.svg'));
const IconListUl = dynamic(() => import('@icons/list-ul.svg'));
const IconLock = dynamic(() => import('@icons/lock.svg'));
const IconLoss = dynamic(() => import('@icons/loss.svg'));
const IconMatches = dynamic(() => import('@icons/matches.svg'));
const IconMedianBlock = dynamic(() => import('@icons/median-block.svg'));
const IconMenu = dynamic(() => import('@icons/menu.svg'));
const IconMinus = dynamic(() => import('@icons/minus.svg'));
const IconMinusBlock = dynamic(() => import('@icons/minus-block.svg'));
const IconMinusCenter = dynamic(() => import('@icons/minus-circle-center.svg'));
const IconMinusCircle = dynamic(() => import('@icons/minus-circle.svg'));
const IconModerator = dynamic(() => import('@icons/moderator.svg'));
const IconPenaltyDisapproved = dynamic(() => import('@icons/disapproved-penalty.svg'));
const IconPenaltyMissed = dynamic(() => import('@icons/penalty-missed.svg'));
const IconPenaltyScored = dynamic(() => import('@icons/penalty-scored.svg'));
const IconPlay = dynamic(() => import('@icons/play.svg'));
const IconPlayedMatches = dynamic(() => import('@icons/played-matches.svg'));
const IconPlus = dynamic(() => import('@icons/plus.svg'));
const IconPlusBlock = dynamic(() => import('@icons/plus-block.svg'));
const IconPlusCenter = dynamic(() => import('@icons/plus-circle-center.svg'));
const IconPlusCircle = dynamic(() => import('@icons/plus-circle.svg'));
const IconPosition = dynamic(() => import('@icons/position.svg'));
const IconPro = dynamic(() => import('@icons/pro.svg'));
const IconProInverse = dynamic(() => import('@icons/pro-inverse.svg'));
const IconPxrConnect = dynamic(() => import('@icons/pxr-connect.svg'));
const IconRead = dynamic(() => import('@icons/read.svg'));
const IconRedCard = dynamic(() => import('@icons/red-card.svg'));
const IconRedCardCancelled = dynamic(() => import('@icons/cancelled-red-card.svg'));
const IconRemoveComment = dynamic(() => import('@icons/comment-remove.svg'));
const IconRemoveFlag = dynamic(() => import('@icons/remove-flag.svg'));
const IconRight = dynamic(() => import('@icons/right.svg'));
const IconRightCenter = dynamic(() => import('@icons/right-center.svg'));
const IconSearch = dynamic(() => import('@icons/search.svg'));
const IconSecondYellowCard = dynamic(() => import('@icons/second-yellow-card.svg'));
const IconSecondYellowCardCancelled = dynamic(() => import('@icons/cancelled-second-yellow-card.svg'));
const IconSettings = dynamic(() => import('@icons/settings.svg'));
const IconShow = dynamic(() => import('@icons/show.svg'));
const IconSignOut = dynamic(() => import('@icons/sign-out.svg'));
const IconStar = dynamic(() => import('@icons/star.svg'));
const IconStatusBall = dynamic(() => import('@icons/status-ball.svg'));
const IconStopwatch = dynamic(() => import('@icons/stopwatch.svg'));
const IconSubstitution = dynamic(() => import('@icons/substitution.svg'));
const IconSwitchIn = dynamic(() => import('@icons/switch-in.svg'));
const IconSwitchOut = dynamic(() => import('@icons/switch-out.svg'));
const IconTextCenter = dynamic(() => import('@icons/text-center.svg'));
const IconTextJustify = dynamic(() => import('@icons/text-justify.svg'));
const IconTextLeft = dynamic(() => import('@icons/text-left.svg'));
const IconTextLink = dynamic(() => import('@icons/text-link.svg'));
const IconTextQuote = dynamic(() => import('@icons/quote.svg'));
const IconTextRight = dynamic(() => import('@icons/text-right.svg'));
const IconTime = dynamic(() => import('@icons/time.svg'));
const IconTrash = dynamic(() => import('@icons/trash.svg'));
const IconCross = dynamic(() => import('@icons/cross.svg'));
const IconTypeBold = dynamic(() => import('@icons/type-bold.svg'));
const IconTypeItalic = dynamic(() => import('@icons/type-italic.svg'));
const IconTypeUnderline = dynamic(() => import('@icons/type-underline.svg'));
const IconUndoRemoveComment = dynamic(() => import('@icons/comment-undo-remove.svg'));
const IconUp = dynamic(() => import('@icons/up.svg'));
const IconUpCenter = dynamic(() => import('@icons/up-center.svg'));
const IconUser = dynamic(() => import('@icons/user.svg'));
const IconUserCircle = dynamic(() => import('@icons/user-circle.svg'));
const IconVideo = dynamic(() => import('@icons/video.svg'));
const IconViews = dynamic(() => import('@icons/views.svg'));
const IconWin = dynamic(() => import('@icons/win.svg'));
const IconX = dynamic(() => import('@icons/x.svg'));
const IconXCircle = dynamic(() => import('@icons/x-circle.svg'));
const IconYellowCard = dynamic(() => import('@icons/yellow-card.svg'));
const IconYellowCardCancelled = dynamic(() => import('@icons/cancelled-yellow-card.svg'));

import styles from './Icon.module.scss';

export enum Option {
    admin = 'admin',
    arrowClockwise = 'arrowClockwise',
    arrowCounterClockwise = 'arrowCounterClockwise',
    arrowEnter = 'arrowEnter',
    arrowSubmit = 'arrowSubmit',
    arrowsDown = 'arrowsDown',
    arrowsRight = 'arrowsRight',
    assist = 'assist',
    banner = 'banner',
    basePlace = 'basePlace',
    bell = 'bell',
    blocks = 'blocks',
    calendar = 'calendar',
    calendarWithDays = 'calendarWithDays',
    cancelledGoal = 'cancelledGoal',
    cancelledOwnGoal = 'cancelledOwnGoal',
    carrousel = 'carrousel',
    check = 'check',
    checkCircle = 'checkCircle',
    chevronDown = 'chevronDown',
    coach = 'coach',
    code = 'code',
    comment = 'comment',
    commentAdd = 'commentAdd',
    commentEmpty = 'commentEmpty',
    commentHide = 'commentHide',
    commentLock = 'commentLock',
    commentShow = 'commentShow',
    commentUnlock = 'commentUnlock',
    connect = 'connect',
    cross = 'cross',
    deleteTrash = 'deleteTrash',
    dossier = 'dossier',
    dots = 'dots',
    doubleDownCenter = 'doubleDownCenter',
    doubleUpCenter = 'doubleUpCenter',
    down = 'down',
    downCenter = 'downCenter',
    draw = 'draw',
    edit = 'edit',
    editor = 'editor',
    field = 'field',
    filter = 'filter',
    flag = 'flag',
    football = 'football',
    footballRed = 'footballRed',
    globe = 'globe',
    graph = 'graph',
    greenOrangeBall = 'greenOrangeBall',
    hide = 'hide',
    hideUser = 'hideUser',
    home = 'home',
    iconVideo = 'iconVideo',
    league = 'league',
    left = 'left',
    leftCenter = 'leftCenter',
    like = 'like',
    likeFilled = 'likeFilled',
    listBig = 'listBig',
    listOl = 'listOl',
    listSmall = 'listSmall',
    listUl = 'listUl',
    lock = 'lock',
    loss = 'loss',
    matches = 'matches',
    medianBlock = 'medianBlock',
    menu = 'menu',
    minus = 'minus',
    minusBlock = 'minusBlock',
    minusCenter = 'minusCenter',
    minusCircle = 'minusCircle',
    moderator = 'moderator',
    penaltyDisapproved = 'penaltyDisapproved',
    penaltyMissed = 'penaltyMissed',
    penaltyScored = 'penaltyScored',
    play = 'play',
    playedMatches = 'playedMatches',
    plus = 'plus',
    plusBlock = 'plusBlock',
    plusCenter = 'plusCenter',
    plusCircle = 'plusCircle',
    position = 'position',
    pro = 'pro',
    proInverse = 'proInverse',
    pxrConnect = 'pxrConnect',
    read = 'read',
    redCard = 'redCard',
    redCardCancelled = 'redCardCancelled',
    removeComment = 'removeComment',
    removeFlag = 'removeFlag',
    right = 'right',
    rightCenter = 'rightCenter',
    search = 'search',
    secondYellowCard = 'secondYellowCard',
    secondYellowCardCancelled = 'secondYellowCardCancelled',
    settings = 'settings',
    show = 'show',
    signOut = 'signOut',
    star = 'star',
    statusBall = 'statusBall',
    stopwatch = 'stopwatch',
    substitution = 'substitution',
    switchIn = 'switchIn',
    switchOut = 'switchOut',
    textCenter = 'textCenter',
    textJustify = 'textJustify',
    textLeft = 'textLeft',
    textLink = 'textLink',
    textQuote = 'textQuote',
    textRight = 'textRight',
    time = 'time',
    trash = 'trash',
    typeBold = 'typeBold',
    typeItalic = 'typeItalic',
    typeUnderline = 'typeUnderline',
    undoRemoveComment = 'undoRemoveComment',
    up = 'up',
    upCenter = 'upCenter',
    user = 'user',
    userCircle = 'userCircle',
    views = 'views',
    win = 'win',
    x = 'x',
    xCircle = 'xCircle',
    yellowCard = 'yellowCard',
    yellowCardCancelled = 'yellowCardCancelled',
}

export const isOption = (x: unknown): x is Option => typeof Option[x as Option] !== 'undefined';

const keepColorList = [
    Option.arrowEnter,
    Option.assist,
    Option.cancelledGoal,
    Option.cancelledOwnGoal,
    Option.coach,
    Option.down,
    Option.field,
    Option.footballRed,
    Option.loss,
    Option.penaltyDisapproved,
    Option.penaltyMissed,
    Option.penaltyScored,
    Option.pro,
    Option.proInverse,
    Option.redCard,
    Option.redCardCancelled,
    Option.secondYellowCard,
    Option.secondYellowCardCancelled,
    Option.settings,
    Option.substitution,
    Option.switchIn,
    Option.switchOut,
    Option.win,
    Option.yellowCard,
    Option.yellowCardCancelled,
];

const IconByOption = (option: Option): typeof IconComment | null => {
    switch (option) {
        case Option.admin:
            return IconAdmin;
        case Option.arrowClockwise:
            return IconArrowClockwise;
        case Option.arrowCounterClockwise:
            return IconArrowCounterClockwise;
        case Option.arrowEnter:
            return IconArrowEnter;
        case Option.arrowSubmit:
            return IconArrowSubmit;
        case Option.arrowsDown:
            return IconArrowsDown;
        case Option.arrowsRight:
            return IconArrowsRight;
        case Option.assist:
            return IconAssist;
        case Option.banner:
            return IconBanner;
        case Option.basePlace:
            return IconBasePlace;
        case Option.bell:
            return IconBell;
        case Option.blocks:
            return IconBlocks;
        case Option.calendar:
            return IconCalendar;
        case Option.calendarWithDays:
            return IconCalendarWithDays;
        case Option.cancelledGoal:
            return IconCancelledGoal;
        case Option.cancelledOwnGoal:
            return IconCancelledOwnGoal;
        case Option.carrousel:
            return IconCarrousel;
        case Option.check:
            return IconCheck;
        case Option.checkCircle:
            return IconCheckCircle;
        case Option.chevronDown:
            return IconChevronDown;
        case Option.coach:
            return IconCoach;
        case Option.code:
            return IconCode;
        case Option.comment:
            return IconComment;
        case Option.commentEmpty:
            return IconCommentEmpty;
        case Option.commentHide:
            return IconCommentHide;
        case Option.commentLock:
            return IconCommentLock;
        case Option.commentShow:
            return IconCommentShow;
        case Option.commentUnlock:
            return IconCommentUnlock;
        case Option.commentAdd:
            return IconCommentAdd;
        case Option.connect:
            return IconConnect;
        case Option.cross:
            return IconCross;
        case Option.deleteTrash:
            return IconDeleteTrash;
        case Option.dossier:
            return IconDossier;
        case Option.dots:
            return IconDots;
        case Option.doubleDownCenter:
            return IconDoubleDownCenter;
        case Option.doubleUpCenter:
            return IconDoubleUpCenter;
        case Option.down:
            return IconDown;
        case Option.downCenter:
            return IconDownCenter;
        case Option.draw:
            return IconDraw;
        case Option.edit:
            return IconEdit;
        case Option.editor:
            return IconEditor;
        case Option.field:
            return IconField;
        case Option.filter:
            return IconFilter;
        case Option.flag:
            return IconFlag;
        case Option.football:
            return IconFootball;
        case Option.footballRed:
            return IconFootballRed;
        case Option.globe:
            return IconGlobe;
        case Option.graph:
            return IconGraph;
        case Option.greenOrangeBall:
            return IconGreenOrangeBall;
        case Option.hide:
            return IconHide;
        case Option.hideUser:
            return IconHideUser;
        case Option.home:
            return IconHome;
        case Option.iconVideo:
            return IconVideo;
        case Option.league:
            return IconLeague;
        case Option.left:
            return IconLeft;
        case Option.leftCenter:
            return IconLeftCenter;
        case Option.like:
            return IconLike;
        case Option.likeFilled:
            return IconLikeFilled;
        case Option.listBig:
            return IconListBig;
        case Option.listOl:
            return IconListOl;
        case Option.listSmall:
            return IconListSmall;
        case Option.listUl:
            return IconListUl;
        case Option.lock:
            return IconLock;
        case Option.loss:
            return IconLoss;
        case Option.matches:
            return IconMatches;
        case Option.medianBlock:
            return IconMedianBlock;
        case Option.menu:
            return IconMenu;
        case Option.minus:
            return IconMinus;
        case Option.minusBlock:
            return IconMinusBlock;
        case Option.minusCenter:
            return IconMinusCenter;
        case Option.minusCircle:
            return IconMinusCircle;
        case Option.moderator:
            return IconModerator;
        case Option.penaltyDisapproved:
            return IconPenaltyDisapproved;
        case Option.penaltyMissed:
            return IconPenaltyMissed;
        case Option.penaltyScored:
            return IconPenaltyScored;
        case Option.play:
            return IconPlay;
        case Option.playedMatches:
            return IconPlayedMatches;
        case Option.plus:
            return IconPlus;
        case Option.plusBlock:
            return IconPlusBlock;
        case Option.plusCenter:
            return IconPlusCenter;
        case Option.plusCircle:
            return IconPlusCircle;
        case Option.position:
            return IconPosition;
        case Option.pro:
            return IconPro;
        case Option.proInverse:
            return IconProInverse;
        case Option.pxrConnect:
            return IconPxrConnect;
        case Option.read:
            return IconRead;
        case Option.redCard:
            return IconRedCard;
        case Option.redCardCancelled:
            return IconRedCardCancelled;
        case Option.removeComment:
            return IconRemoveComment;
        case Option.removeFlag:
            return IconRemoveFlag;
        case Option.right:
            return IconRight;
        case Option.rightCenter:
            return IconRightCenter;
        case Option.search:
            return IconSearch;
        case Option.secondYellowCard:
            return IconSecondYellowCard;
        case Option.secondYellowCardCancelled:
            return IconSecondYellowCardCancelled;
        case Option.settings:
            return IconSettings;
        case Option.show:
            return IconShow;
        case Option.signOut:
            return IconSignOut;
        case Option.star:
            return IconStar;
        case Option.statusBall:
            return IconStatusBall;            
        case Option.stopwatch:
            return IconStopwatch;
        case Option.substitution:
            return IconSubstitution;
        case Option.switchIn:
            return IconSwitchIn;
        case Option.switchOut:
            return IconSwitchOut;
        case Option.textCenter:
            return IconTextCenter;
        case Option.textJustify:
            return IconTextJustify;
        case Option.textLeft:
            return IconTextLeft;
        case Option.textLink:
            return IconTextLink;
        case Option.textQuote:
            return IconTextQuote;
        case Option.textRight:
            return IconTextRight;
        case Option.time:
            return IconTime;
        case Option.trash:
            return IconTrash;
        case Option.typeBold:
            return IconTypeBold;
        case Option.typeItalic:
            return IconTypeItalic;
        case Option.typeUnderline:
            return IconTypeUnderline;
        case Option.undoRemoveComment:
            return IconUndoRemoveComment;
        case Option.up:
            return IconUp;
        case Option.upCenter:
            return IconUpCenter;
        case Option.user:
            return IconUser;
        case Option.userCircle:
            return IconUserCircle;
        case Option.views:
            return IconViews;
        case Option.win:
            return IconWin;
        case Option.x:
            return IconX;
        case Option.xCircle:
            return IconXCircle;
        case Option.yellowCard:
            return IconYellowCard;
        case Option.yellowCardCancelled:
            return IconYellowCardCancelled;
        default:
            return null;
    }
};

interface Props {
    onClick?: MouseEventHandler<HTMLSpanElement>;
}

type Element = MemoExoticComponent<(props: Props) => JSX.Element> | ((props: Props) => JSX.Element);
type Type = Record<Option, Element>;

// FIXME: Dirty fix to make inherit-colors and memo work in storybook
declare global {
    interface Window {
        __STORYBOOK_PREVIEW__: any;
    }
}
const isStoryBook = typeof window === 'object' && window?.__STORYBOOK_PREVIEW__;

const IconElement = (type: Option) => {
    return (props: Props): ReactElement => {
        const classes = ['Icon', styles.Icon, Option[type] + '-icon'];
        if (!keepColorList.includes(type)) {
            classes.push('inherit-colors', styles['inherit-colors']);
        }

        const _props = {
            ...props,
            className: classes.join(' '),
            style: {
                cursor: props.onClick ? 'pointer' : 'inherit',
            },
        };

        const _svgProps = {
            width: '100%',
            height: '100%',
            viewBox: '0 0 24 24',
            preserveAspectRatio: 'xMidYMid meet',
        };

        const ChosenIcon = IconByOption(type);
        if (ChosenIcon) {
            // FIXME: webpack couldn't properly import style from common folder
            return (
                <span {..._props}>
                    <ChosenIcon {..._svgProps} />
                </span>
            );
        } else {
            return <span {..._props}>Icon.{type} is not yet implemented</span>;
        }
    };
};

export const Icon: Type = ((): Type => {
    const options: Partial<Type> = {};
    Object.values(Option).forEach((key) => {
        options[key] = isStoryBook ? IconElement(key) : memo(IconElement(key));
    });
    return options as Type;
})();
